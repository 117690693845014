<template>
  <div id="goods">
    <div class="goods_params wrap">
      <div class="block clearfix">
        <div class="left_info">类型:</div>
        <ul class="fl">
          <li
            @click="(formData.type = ''), search(-1)"
            :class="formData.type == '' ? 'active' : ''"
          >
            全部类型
          </li>
        </ul>
      </div>
      <div class="block clearfix">
        <div class="left_info">仓储：</div>
        <ul class="fl">
          <li
            @click="(formData.storeId = ''), search(-1)"
            :class="formData.storeId == '' ? 'active' : ''"
          >
            全部仓储
          </li>
          <li
            @click="(formData.storeId = item.id), search(index)"
            :class="formData.storeId == item.id ? 'active' : ''"
            v-for="(item,index) in storeList"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="block clearfix">
      </div>
    </div>
    <div class="goods_list wrap clearfix">
      <div class="item" v-for="item in goodsList" @click="buyGoods(item)">
        <dl>
          <dt>
            <img :src="item.cover | fullPath" alt="" />
          </dt>
          <dd class="title">
            {{ item.name }}
          </dd>
          <dd class="other">
            <span class="price fl"
              >￥{{
                (parseFloat(item.cost) + parseFloat(item.profit)).toFixed(2)
              }}</span
            >
            <span class="fr">{{ item.weight }} kg/件</span>
          </dd>
          <dd class="btn">立即购买</dd>
        </dl>
      </div>
    </div>
    <div class="home_fy_block wrap">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="formData.pageNo"
        :page-size="formData.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      formData: {
        pageNo: 1,
        pageSize: 20,
        sort: "",
        storeId: "",
        type: "",

        tenantId: localStorage.getItem("tenantInfoid"),
      },
      index:0,
      total: 0,
      goodsList: [],
      typeList: [],
      storeList: [],
    };
  },
  created() {
    this.getTypeList();
    this.getStoreList();
    this.getGoodsList();
    this.IsPC();
  },
  mounted() {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.formData.pageNo = val;
      this.getGoodsList();
    },
    search(i) {
      this.formData.pageNo = 1;
      this.getGoodsList(i);
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$router.push("/m_goodsall");
      }
    },

    getTypeList() {
      this.$request.post({
        url: "/ReportApi/business/goodsType/getGoodsType",
        params: {},
        success: (res) => {

          for (let i = 0; i < res.length; i++) {
            res[i].createTime = res[i].create_time;
            delete res[i].create_time;
            res[i].otberId = res[i].other_id;
            delete res[i].other_id;
            res[i].otherType = res[i].other_type;
            delete res[i].other_type;
          }
          this.typeList = res;

        },
      });
    },

    getStoreList() {
      this.$request.post({
        url: "/ReportApi/business/goodsStore/getStoreInfo",
        success: (res) => {

          let forData = [];
          for (let i = 0; i < res.length; i++) {
            if (!forData.some(e => e.id == res[i].id)) forData.push(res[i]);
          }

          this.storeList = forData;

        },
      });
    },

    getGoodsList(i) {
      if(this.formData.storeId == ''){
        this.$request.post({
          url: "/ReportApi/business/goods/getGoodsInfo",
          params: this.formData,
          success: (res) => {
            this.goodsList = res.data;
            this.total = res.total;
          },
        });
        return
      }
      this.goodsList =  this.storeList[i].goodsInfoVOS
      this.total = this.goodsList.length;
    },

    buyGoods(item) {
      this.$store.commit("getGoodsInfo", item);
      this.$router.push("/member/send_order");
    },
  },
};
</script>

<style lang="scss" scoped>
#goods {
  .goods_params {
    border: 1px solid #eee;
    border-radius: 5px;
    font-size: 14px;
    color: #666;
    .block {
      line-height: 50px;
      background: #fafafa;
      &:nth-child(1) {
        border-bottom: 1px solid #eee;
        background: #fff;
      }
      .left_info {
        float: left;
        padding-left: 20px;
        padding-right: 15px;
      }
      line-height: 50px;
      ul {
        li {
          float: left;
          padding: 0 10px;
          &:hover {
            cursor: pointer;
            color: #ea242f;
          }
        }
        .active {
          font-weight: bold;
          color: #ea242f;
        }
      }
    }
  }
  .goods_list {
    margin-top: 20px;
    margin-bottom: 20px;
    .item {
      width: 226px;
      border: 1px solid #eee;
      margin-bottom: 10px;
      margin-left: 10px;
      float: left;
      &:hover {
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
      }
      dl {
        dt {
          width: 210px;
          margin: 0 auto;
          margin-top: 8px;
          img {
            width: 210px;
            height: 210px;
          }
        }
        dd {
          padding: 0 8px;
          font-size: 14px;
          color: #999;
        }
        .title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 34px;
        }
        .other {
          height: 28px;
          .price {
            color: #ff0036;
            font-size: 16px;
            font-weight: bold;
          }
        }
        .num {
          line-height: 34px;
          color: #333;
          border-top: 1px solid #efefef;
          border-bottom: 1px solid #efefef;
        }
        .btn {
          border-radius: 5px;
          color: #ea242f;
          border: 1px solid #ea242f;
          line-height: 28px;
          width: 180px;
          text-align: center;
          margin: 10px auto;
          &:hover {
            background: #ea242f;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
